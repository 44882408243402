import React, { FC, memo } from 'react';
import styles from './App.module.scss';
import { useMobile } from '../../hooks/useMobile';
import PayslipViewer from '../PayslipViewer/PayslipViewer';

interface Props {

}

const App: FC<Props> = memo(() => {
  const isMobile = useMobile();

  if (!isMobile) {
    return (
      <div className={styles.app}>
        <h1 className={styles.notMobileMessage}>모바일에서 접속해 주세요.</h1>
      </div>
    )
  }

  return (
    <div className={styles.app}>
      <PayslipViewer />
    </div>
  );
});

export default App;
