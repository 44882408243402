export class Payslip {
  static parse(data: string, birthday: string) {
    const bin = unescape(data).split(',');
    const result: string[] = [];

    for (var i = 0; i < bin.length; i++) {
      result[i] = String.fromCharCode(Number(bin[i]) + birthday.charCodeAt(i % birthday.length));
    }

    const html = result.join('');

    const bodyOpenIndex = html.indexOf('<body');
    const bodyCloseIndex = html.indexOf('</body');

    return html
      .substring(bodyOpenIndex, bodyCloseIndex)
      .replace(/<body[^>]+>/g, '')
      .replace(/\s?width='\d+px'\s?/g, '');
  }
}
