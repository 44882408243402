import React, { FC, FormEvent, memo, useCallback, useEffect, useState } from 'react';
import styles from './PayslipViewer.module.scss';
import { Payslip } from '../../cores/Payslip';
import { Parser } from 'htmlparser2';
import { get, map } from 'lodash';
import { MdFileUpload } from 'react-icons/md';
import Ink from 'react-ink';

interface Props {}

const PayslipViewer: FC<Props> = memo(() => {
  const [viewData, setViewData] = useState('');
  const [password, setPassword] = useState('');
  const [birthday, setBirthday] = useState('');
  const [innerHtml, setInnerHtml] = useState('');

  useEffect(() => {
    if (birthday === '' || password === '' || viewData === '') {
      return;
    }

    const numericPassword = parseInt(password);
    const numericBirthday = parseInt(birthday);

    if (numericPassword === Math.abs(numericBirthday << numericBirthday)) {
      setInnerHtml(Payslip.parse(viewData, birthday));
    }
  }, [viewData, password, birthday]);

  const onChange = useCallback((e: FormEvent<HTMLInputElement>) => {
    const files = e.currentTarget.files;

    if (!files) {
      return;
    }

    if (files.length <= 0) {
      return;
    }

    const file = files[0];

    if (!/^text\/html$/.test(file.type)) {
      return;
    }

    const reader = new FileReader();
    reader.readAsText(file);
    reader.addEventListener('load', () => {
      const result = reader.result;
      if (typeof result !== 'string') {
        return;
      }

      let viewData: string = '';
      let birthday: string = '';

      const parser = new Parser({
        onopentag(name: string, attributes: { [p: string]: string }): void {
          switch (name) {
            case 'input':
              if (get(attributes, 'name') === '_viewData') {
                viewData = get(attributes, 'value');
              }
              break;
          }
        }
      });
      parser.write(result);
      parser.end();

      const passwordMatches = result.match(/unescape\([^)]+\)/);

      if (!passwordMatches || passwordMatches.length <= 0) {
        return;
      }

      const encodedPasswordMatch = passwordMatches[0];
      const encodedPassword = encodedPasswordMatch.substring(
        encodedPasswordMatch.indexOf("'") + 1,
        encodedPasswordMatch.lastIndexOf("'")
      );

      const password = Number(
        map([...encodedPassword.matchAll(/\d+/g)], matches => String.fromCharCode(parseInt(get(matches, 0), 16))).join('')
      );

      for (let i = 1; i <= 30; i++) {
        const shifted = password >> i;

        if (password === Math.abs(shifted << shifted)) {
          birthday = shifted.toString();
          break;
        }
      }

      if (viewData === '') {
        return;
      }

      setViewData(viewData);
      setPassword(password.toString());

      if (birthday !== '') {
        setBirthday(birthday);
      }
    });
  }, []);

  const isInputted = viewData !== '' && innerHtml === '';

  return (
    <div className={styles.payslipViewer}>
      {innerHtml ? (
        <div dangerouslySetInnerHTML={{ __html: innerHtml }}></div>
      ) : isInputted ? (
        <input
          type="text"
          value={birthday}
          placeholder="생년월일 (6자리)"
          className={styles.birthdayInput}
          onChange={(e: FormEvent<HTMLInputElement>) => {
            setBirthday(e.currentTarget.value.replace(/\D/g, ''));
          }}
        />
      ) : (
        <label className={styles.fileUpload}>
          <input type="file" onChange={onChange} accept="text/html" />
          <MdFileUpload />
          급여명세서 업로드
          <Ink />
        </label>
      )}
    </div>
  );
});

export default PayslipViewer;
